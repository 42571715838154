.login-container {
  height: 100%;
  position: absolute;
  width: 100%;
}

.login-container .container-fluid {
  height: 100%;
}

.login-container .row {
  height: 100%;
}