body {
  height: 100%;
}

#root {
  height: 100%;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(0px);
  }
}


/**Login */

.login-container {
  background: rgb(226, 226, 226);
  background: linear-gradient(117deg, #e2e2e2 43%, #42B777 43%, rgba(58, 67, 140, 0.5046393557422969) 100%);
}

.demo-img-container {
  position: relative;
  overflow: hidden;
  height: 100%;
}

.demo-img {
  -webkit-box-shadow: 5px 5px 26px 5px rgba(0, 0, 0, 0.29);
  box-shadow: 5px 5px 26px 5px rgba(0, 0, 0, 0.29);
}

.demo-img1 {
  position: absolute;
  top: 20%;
  left: 5%;
  width: 45%;
}

.demo-img2 {
  position: absolute;
  top: 32%;
  left: 27%;
  width: 45%;
}

.demo-img3 {
  position: absolute;
  top: 25%;
  left: 35%;
  width: 55%;
}

.demo-text {
  color: rgb(229, 229, 229);
  position: absolute;
  top: 10%;
  left: 0%;
  width: 100%;
  text-align: center;
  -webkit-text-shadow: 5px 5px 26px 5px rgba(0, 0, 0, 0.29);
  text-shadow: 0px 0px 15px rgba(0, 0, 0, 1);
}